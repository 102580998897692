import styled from 'styled-components';

export const HeaderBody = styled.div`
    width: 100%;
    height: 90vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    box-sizing: border-box;
    gap: 1rem;
    flex-direction: column;

    .content-50a, .content-50b {
        width: 100%;
    }

    .content-50a {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 1.5vh;
        margin-top: 1vh;

        h1 {
            color: orange;
            font-size: 6vw;
        }

        h2 {
            color: white;
            font-size: 5vw;

            span {
                color: orange;
            }
        }

        h3, a {
            color: #00f01c;
            font-size: 4vw;

            &:hover {
                cursor: pointer;
            }
        }

        hr {
            width: 30%;
            border-radius: 50%;
        }

        p {
            font-size: 2.5vw;
            color: white;
        }
    }
`

export const FormLead = styled.form`
    border: 2px solid orange;
    display: flex;
    flex-direction: column;
    padding: 5vh 1rem;
    background-image: url('../../assets/imgs/BckForm.png');
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 0.5rem;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 0.4rem;
    position: relative;
    margin: 0 auto 8vh auto;

    img {
        width: 35vw;
        background-color: black;
        border-radius: 0.8rem;    
    }

    h1, h2 {
        font-size: 1rem;
        border-top: 1px solid black;
        margin-top: 0.6rem;
        padding-top: 0.6rem;
        text-shadow: 5px 3px 10px rgba(0,0,0,0.36);
    }

    h1 {
        color: #021263;
    }

    input {
        width: 90%;
        text-align: center;
        font-size: 0.8rem;
        padding: 0.2rem 0;

        &::placeholder {
            text-align: center;   
        }

        &:focus {
            outline: none;
            font-size: 3.5vw;
            border: 1px solid green;
        }
    }

    button {
        margin-top: 1rem;
        background-color: orange;
        border-radius: 0.6rem;
        padding: 0.3rem 1rem;
        color: white;
        font-weight: 700;

        &:hover {
            cursor: pointer;
            background-color: green;
        }
    }
`

export const FooterHeader = styled.div`
    width: 100%;
    background-color: white;
    margin-top: 2rem;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const UlFooter = styled.ul`
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 0.2rem 0;
    gap: 2vw;

    li {
        list-style-type: none;
    }

    li p {
        text-decoration: none;
        color: black;
        font-size: 3vw;
    }

`

export const ContainerHeader = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
`

export const IframeYT = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
`

export const IframeContainer = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
`