import { useState } from "react";
import emailjs from '@emailjs/browser';
import { Wrapper } from "../../styles/Wrapper";
import { ContainerHeader, FooterHeader, FormLead, HeaderBody, IframeContainer, IframeYT, UlFooter } from "./styles";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faLock } from "@fortawesome/free-solid-svg-icons";

const Header: React.FC = () => {

    const whatsUrl = 'https://api.whatsapp.com/send?phone=555192770784&text=Ol%C3%A1!%20Gostaria%20de%20iniciar%20uma%20Simula%C3%A7%C3%A3o%20Gr%C3%A1tis!'

    const whatsString = '(51) 99277-0784'

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [whats, setWhats] = useState('');

    const sendEmail = (e: any) => {
        e.preventDefault();

        if (name === '' || email === '' || whats === ''){
            alert('Por favor, preenche todos os campos ou fale conosco através do WhatsApp para maiores informações!');
            return
        }

        const templateParams = {
            from_name: name,
            email: email,
            whats: whats,
        }

        emailjs.send('service_i9tt9yx', 'template_aejoc9h', templateParams, '9qKP69-7rmWYiFeGV')
        .then((response) => {
            console.log('Email Enviado', response.status, response.text);
            alert('Inscrição enviada com sucesso!')
            setName('');
            setEmail('');
            setWhats('');
        }, (err) => {
            console.log('Fail', err);
        })
    }

    return(
        <ContainerHeader>
            <Wrapper>
                <HeaderBody>
                    <div className="content-50a">
                        <h1>A Maneira Mais RÁPIDA de ter SEU IMÓVEL!</h1>
                        <p>Programa Minha Casa, Minha Vida</p>
                        <IframeContainer>
                            <IframeYT
                                src="https://www.youtube.com/embed/7gVs2ecQ0hA?autoplay=1&color=red&controls=0&showinfo=0&modestbranding=1&iv_load_policy=3" 
                                title="YouTube video player" 
                                id="click-video" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" 
                                allowFullScreen>
                            </IframeYT>
                        </IframeContainer>
                        <p>Ajuste seu volume, o vídeo vai começar!</p>

                        <h2>Imóveis de <span>até R$350MIL</span></h2>
                        <hr />
                        <h2>Parcelas Menores <br />Que Alugueis!</h2>
                        <hr />
                        <a href={whatsUrl} style={{textDecoration: 'none'}} id="click-whats">Inscrição Rápida no WhatsApp!</a>
                        <hr />
                        <a href={whatsUrl} style={{textDecoration: 'none'}} id="click-whats">{whatsString}</a>
                        <p>Clique no número acima e inscreva-se agora!</p>
                    </div>

                    <div className="content-50b">
                        <FormLead id="fullForm" onSubmit={sendEmail}>
                            <img src="../../assets/imgs/BodyImgNB.png" alt="" />
                            <h2 style={{color: 'orange'}}>Inscrição Fácil - 2023</h2>
                            <h1>Minha Casa, Minha Vida</h1>
                            <label>Nome Completo:</label>
                            <input 
                                onChange={(e) => setName(e.target.value)}
                                type="text" 
                                id="nameLabel" 
                                placeholder="Nome e Sobrenome"
                                value={name}
                            />
                            <label>Email:</label>
                            <input 
                                onChange={(e) => setEmail(e.target.value)}
                                type="email" 
                                id="amountLabel" 
                                placeholder="exemplo@exemplo.com"
                                value={email}
                            />
                            <label>WhatsApp:</label>
                            <input 
                                onChange={(e) => setWhats(e.target.value)}
                                type="number" 
                                id="whatsNum" 
                                placeholder="(51) 9 9999-9999"
                                value={whats}
                            />
                            <button type="submit" id="click-form-submit">Inscrever-se!</button>
                        </FormLead>
                    </div>
                    <a href={whatsUrl} id="click-whats">
                    <FontAwesomeIcon icon={faWhatsapp} bounce size="2xl" style={{color: "#00f01c", fontSize: '3rem', position: 'fixed', right: '10%', bottom: '4rem'}} />
                    </a>
                    
                </HeaderBody>
            </Wrapper>

            <FooterHeader>
                <Wrapper>
                    <UlFooter>
                        <FontAwesomeIcon icon={faLock}/>
                        <li><p>Site Seguro</p></li>
                    </UlFooter>
                </Wrapper>
            </FooterHeader>
        </ContainerHeader>
    )
}

export default Header;