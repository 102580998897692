import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Carroussel from "../components/Carroussel";
import { FooterHeader, UlFooter } from "../components/Header/styles";
import NavBar from "../components/NavBar";
import GlobalStyle from "../styles/Global.style";
import { Wrapper } from "../styles/Wrapper";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const Catalogo = () => {

    const whatsUrl = 'https://api.whatsapp.com/send?phone=555192770784&text=Ol%C3%A1!%20Gostaria%20de%20iniciar%20uma%20Simula%C3%A7%C3%A3o%20Gr%C3%A1tis!'

    return(
        <>
            <GlobalStyle/>
            <NavBar/>
            <Carroussel/>
            <FooterHeader>
                <Wrapper>
                    <UlFooter>
                        <FontAwesomeIcon icon={faLock}/>
                        <li><p>Site Seguro</p></li>
                    </UlFooter>
                </Wrapper>
                
                <a href={whatsUrl} id="click-whats">
                    <FontAwesomeIcon icon={faWhatsapp} bounce size="2xl" style={{color: "#00f01c", fontSize: '3rem', position: 'fixed', right: '10%', bottom: '4rem'}} />
                </a>            
            </FooterHeader>
        </>
    )
}

export default Catalogo;