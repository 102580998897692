import GlobalStyle from "../styles/Global.style";
import NavBar from "../components/NavBar";
import Header from "../components/Header";

const Home = () => {
    return(
        <>  
            <GlobalStyle/>
            <NavBar/>
            <Header/>
        </>
    )
}

export default Home;