import styled from 'styled-components';

export const FullBck = styled.div`
    width: 100%;
    background-image: linear-gradient(#193763, rgba(255,255,255,0.0005));
`

export const LinksUl = styled.ul`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 1rem 2rem;

    li {
        list-style-type: none;
    }

    li a {
        text-decoration: none;
        color: white;
        font-size: 3vw;

        &:hover {
            color: orange;
        }
    }
`