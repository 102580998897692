import styled from "styled-components";

export const CarrousselWrapper = styled.div`
    width: 100%;
    height: auto;
    border: 1px solid orange;
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    margin: 2vh 0 2vh 0;
`

export const TitleDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
`
export const TitleDiv2 = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1vh;
    margin: 1vh;

    a {
        color: green;
        text-decoration: none;
    }
`

export const TitleCarsl = styled.h1`
    color: orange;
    font-size: 6vw;
    margin-top: 6vh;
    margin-bottom: 1vh;
`

export const TitleCarsl2 = styled.h2`
    color: white;
    font-size: 3vw;
`

export const TitleCarsl3 = styled.h3`
    color: green;
    font-size: 4vw;
`