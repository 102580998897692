import { Wrapper } from '../../styles/Wrapper';
import {FullBck, LinksUl} from './styles';
import { useNavigate } from "react-router-dom";

const NavBar: React.FC = () => {

    const whatsUrl = 'https://api.whatsapp.com/send?phone=555192770784&text=Ol%C3%A1!%20Gostaria%20de%20iniciar%20uma%20Simula%C3%A7%C3%A3o%20Gr%C3%A1tis!'

    const navigate = useNavigate();

    const goToCatalog = () => {
        navigate("catalogo");
     }

    return(
        <FullBck>
            <Wrapper>
                <LinksUl>
                    <li><a href={whatsUrl} id="click-whats">Inscrição Ágil - WhatsApp</a></li>
                    <li><a onClick={goToCatalog} id="click-catalogo">Catálogo</a></li>              
                </LinksUl>
            </Wrapper>
        </FullBck>
    )
}

export default NavBar;